/** @jsx jsx */
import { jsx, Flex, Text, Box } from 'theme-ui'
import React from "react"
import { graphql, Link } from "gatsby"
import { keyframes } from '@emotion/core'

import Layout from "../components/layout"
import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout location={this.props.location} title={siteTitle} style={{display: "flex"}}>
        
        <Box sx={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
          width: "100vw",
          height: "50vh",
          animation: `1s ease-in normal forwards ${top}`,
          bg: "#000",
          }}></Box>
        <SEO title="404: Not Found" />
        <Flex sx={{width: "100%", justifyContent: "center", flexDirection: "column", alignItems: "center", textAlign: "center", mixBlendMode: "difference"}}>
          <Text sx={{fontSize: '7vw', color: "#fff", letterSpacing: '-.042em', lineHeight: "0.94em", mb: [4, 6]}}>Oh dear, you got 404’d</Text>
          <Text variant="h3" sx={{mb: [4,6,8], color: "#fff" }} >The page you are looking for doesn’t exist or has been moved.</Text>
          <Link sx={{variant: 'links.button', px: 8, py: 4,}} to={'/'}><Box sx={{ml: -2}}>← Take me back</Box></Link>
        </Flex>
        <Box sx={{
          position: "absolute",
          bottom: 0,
          zIndex: -1,
          left: 0,
          width: "100vw",
          height: "50vh",
          animation: `1s ease-in normal forwards ${bottom}`,
          bg: "#000"
          }}></Box>
      </Layout>
    )
  }
}

const top = keyframes`
  from {
    transform: translateY(-50vh);
  }
  82.5% {
    transform: translateY(-50vh);
  }
  to {
    transform: translateY(0);
  }
`
const bottom = keyframes`
  from {
    transform: translateY(50vh);
  }
  82.5% {
    transform: translateY(50vh);
  }
  to {
    transform: translateY(0);
  }
`

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
